import React from "react";
import Card from "../../../components/card-industries";
import { motion } from "framer-motion";
import { staggerChildren } from "../../../animations/variants";
import IntersectionBox from "../../../animations/IntersectionBox";
import TrailingParagraph from "../../../animations/TrailingParagraph";
import { useStaticQuery, graphql } from "gatsby";

function getZoomIn(delay = 0) {
  return {
    show: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.5,
        delay: delay,
        type: "spring",
        bounce: 0.6,
        damping: 20,
        mass: 0.5,
        velocity: 1.7,
      },
    },
    hidden: {
      opacity: 0,
      scale: 0.8,
    },
  };
}

function ManufacturingSolutions({ title, description, service_cards }) {
  const [currentIndex, setCurrentIndex] = React.useState(0);

  function shiftCardContainer(index) {
    const elem = document.querySelector(
      ".manufacturing-solutions .card-container"
    );
    const cardWidth = elem.firstChild.offsetWidth;
    const shift = `-${index * cardWidth}px`;
    elem.style.marginLeft = shift;
    setCurrentIndex(index);
  }

  return (
    <div
      className="manufacturing-solutions triggerAnimation animated"
      data-animate="fadeInUp"
    >
      <div className="content">
        <IntersectionBox>
          <h3 className="sol-head"> {title} </h3>
        </IntersectionBox>

        <p className="sol-desc">
          <TrailingParagraph paragraph={description} />
        </p>
      </div>
      <IntersectionBox>
        <div className="tabs-container">
          <div className="tabs">
            {service_cards.map((solution, index) => (
              <a
                href={`#tabs-${index}`}
                className={`${index === currentIndex ? "selected" : ""}`}
                onClick={() => {
                  shiftCardContainer(index);
                }}
              >
                {solution.card_title}
              </a>
            ))}
          </div>
        </div>
      </IntersectionBox>
      <IntersectionBox threshold={0.1}>
        <motion.div className="card-container">
          {service_cards.map((service, index) => (
            <motion.div>
              <Card
                title={service.card_title}
                description={service.visible_text}
                href={service.href}
                img={service.image?.image?.url}
              />
            </motion.div>
          ))}
        </motion.div>
      </IntersectionBox>
    </div>
  );
}

ManufacturingSolutions.defaultProps = {
  title: "Manufacturing Solutions Industry",
  description: "Manufacturing Solutions Description",
  service_cards: [],
};

export default ManufacturingSolutions;
