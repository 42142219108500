import { motion } from "framer-motion";
import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import IntersectionBox from "../../animations/IntersectionBox";
import RenderOnIntersectionBox from "../../animations/RenderOnIntersectionBox";
import TrailingParagraph from "../../animations/TrailingParagraph";
import { fadeInFromLeftWithDelay } from "../../animations/variants";
import GetQuoteSection from "../../components/GetQuoteSection";
import Materials from "../../components/industry/components/materials";
import PostProcessing from "../../components/industry/components/post_processing";
import Processes from "../../components/industry/components/processes";
import Layout from "../../components/layout";
import RequestCallback from "../../components/RequestCallback";
import SEO from "../../components/seo";
function Automotive() {
  const data = useStaticQuery(graphql`
    query {
      strapi {
        industries(where: { industry: "aerospace" }) {
          seo {
            title
            meta_description
            meta_keywords
          }
          banner_image {
            image {
              url
            }
          }
          page_header_description {
            description_text
            heading_text
          }
          industry_usps {
            logo_title
            logo_image {
              image {
                url
              }
            }
          }
          prototyping_type_url {
            name_url {
              name
              url
            }
          }

          cusitv_prototyping {
            description_text
            heading_text
          }
          supply_chain_production {
            description_text
            heading_text
          }
          detailed_description
          industry_services_header {
            description_text
            heading_text
          }
          service_cards {
            card_title
            visible_text
            image {
              image {
                url
              }
            }
            href
          }
          material_heading
          material_card {
            material_description
            material_name
            material_image {
              image {
                url
              }
            }
            material_grades
          }
          industry_specific_parts {
            description_text
            heading_text
          }
          parts_list
          pp_heading
          pp_type {
            PP_title
            expanded_text
            visible_text
            PP_image {
              image {
                url
              }
            }
          }
          rcb_industry {
            form_title_description {
              description_text
              heading_text
            }
          }
          gta_industry {
            get_quote_title_des {
              description_text
              heading_text
            }
          }
        }
      }
    }
  `);

  const node = data.strapi.industries[0];
  return (
    <Layout>
      <SEO
        title={node.seo.title}
        keywords={node.seo.meta_keywords}
        description={node.seo.meta_description}
      />
      <div className='content container-fluid'>
        <div class='appli-section'>
          <div class='appli-intro'>
            <div class='row'>
              <div class='appli-intro-img col-lg-6 col-md-6 col-sm-6 col-xs-12'>
                <IntersectionBox variants={fadeInFromLeftWithDelay(0.1)}>
                  <img
                    src={node.banner_image.image.url}
                    alt='aerospace and defense'
                  />
                </IntersectionBox>
              </div>
              <div class='appli-info col-lg-6 col-md-6 col-sm-6 col-xs-12'>
                <h1 class='appli-name'>
                  <TrailingParagraph
                    paragraph={node.page_header_description.heading_text}
                  />
                </h1>
                <p class='appli-desc'>
                  <TrailingParagraph
                    paragraph={node.page_header_description.description_text}
                  />
                </p>
                <RenderOnIntersectionBox threshold={0.9}>
                  <div className='row appli-feat'>
                    {node.industry_usps.map((usp, index) => (
                      <div
                        class='column col-lg-3 col-md-3 col-sm-3 col-xs-6 fadeInUp'
                        style={{ animationDelay: `${750}ms` }}>
                        <div class='appli-icons'>
                          <figure>
                            <img
                              src={usp.logo_image.image.url}
                              alt={usp.logo_title}
                            />
                          </figure>
                        </div>
                        <p class='appli-feat-desc'> {usp.logo_title} </p>
                      </div>
                    ))}
                  </div>
                </RenderOnIntersectionBox>
              </div>
            </div>
          </div>

          <div class='supply-chain'>
            <div class='row'>
              <div class='chain-info col-lg-6 col-md-6 col-sm-6 col-xs-12'>
                <p class='chain-head'>
                  <TrailingParagraph
                    paragraph={node.supply_chain_production.heading_text}
                  />
                </p>
                <p class='chain-desc'>
                  <TrailingParagraph
                    paragraph={node.supply_chain_production.description_text}
                  />
                </p>
              </div>
              <div class='chain-intro col-lg-6 col-md-6 col-sm-6 col-xs-12'>
                <p class='chain-intro-desc'>
                  <TrailingParagraph paragraph={node.detailed_description} />
                </p>
              </div>
            </div>
          </div>
          <div class='custiv-prototype'>
            <div class='row'>
              <div class='proto-info col-lg-6 col-md-6 col-sm-6 col-xs-12'>
                <p class='proto-head'>
                  <TrailingParagraph
                    paragraph={node.cusitv_prototyping.heading_text}
                  />
                </p>
                <p class='proto-desc'>
                  <TrailingParagraph
                    paragraph={node.cusitv_prototyping.description_text}
                  />
                </p>
              </div>
              <div class='proto-parts col-lg-6 col-md-6 col-sm-6 col-xs-12'>
                <div class='column'>
                  {[
                    {
                      url: "/service/cnc-machining-services",
                      name: "protoytype machining",
                    },
                    {
                      url: "/service/prototyping-services",
                      name: "Additive Manufacturing",
                    },
                    {
                      name: "Vacuum Casting",
                      url: "/service/prototyping-services",
                    },
                  ].map((_process, index) => (
                    <IntersectionBox>
                      <p class='proto-part-list'>
                        <FaCheckCircle />
                        <Link to={_process.url}>{_process.name}</Link>
                      </p>
                    </IntersectionBox>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Processes
          title={node.industry_services_header.heading_text}
          description={node.industry_services_header.description_text}
          service_cards={node.service_cards}
        />
        <Materials
          title={node.material_heading}
          materials={node.material_card}
        />
        <div class='application-parts'>
          <div class='row'>
            <div class='app-part-info col-lg-6 col-md-6 col-sm-6 col-xs-12'>
              <h2 class='app-part-head'>
                <TrailingParagraph
                  paragraph={node.industry_specific_parts.heading_text}
                />
              </h2>
              <p class='app-part-desc'>
                <TrailingParagraph
                  paragraph={node.industry_specific_parts.description_text}
                />
              </p>

              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                class='clippy_button started-btn'
                onClick={() => {
                  window.open("/contact-us", "_blank");
                }}>
                Contact Us
              </motion.button>
            </div>
            <div class='app-part-type col-lg-6 col-md-6 col-sm-6 col-xs-12'>
              <div class='row'>
                <div class='column'>
                  {node.parts_list.split("\n").map((part) => {
                    if (part.length > 0) {
                      return (
                        <IntersectionBox>
                          <p class='app-part-list'>
                            <FaCheckCircle /> {part}
                          </p>
                        </IntersectionBox>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <PostProcessing title={node.pp_heading} pp={node.pp_type} />
        <RequestCallback
          title={node.rcb_industry.form_title_description.heading_text}
          description={
            node.rcb_industry.form_title_description.description_text
          }
        />
        <GetQuoteSection />
      </div>
    </Layout>
  );
}

export default Automotive;
